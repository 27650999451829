import s from './EthicsCompliance.module.css'
import EthicsImg from '../../assets/img/EthicsCompliance/ethicsImg.webp'
import ComplianceImg from '../../assets/img/EthicsCompliance/ComplianceImg.webp'
import CulturalDifImg from '../../assets/img/EthicsCompliance/CulturalDifImg.webp'
import ProtectionImg from '../../assets/img/EthicsCompliance/ProtectionImg.webp'
import SupportImg from '../../assets/img/EthicsCompliance/SupportImg.webp'
import {useTranslation} from "react-i18next";
export const EthicsCompliance = () => {

    const {t} = useTranslation()

    return (
        <div className={s.EthicsCompliance}>
            <div className={s.EthicsComplianceBG}>
                <h3>{t("ethics-compliance")}</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={s.Text}>
                        <h3 className={s.Title}>
                            {t("ethics-mainTitle")}
                        </h3>
                        <p className={s.Desc}>
                            {t("ethics-mainText")}
                        </p>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h4 className={s.FlexTitle}>
                                {t("ethics-title1")}
                            </h4>
                            <p>
                                {t("ethics-text1")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={EthicsImg} alt=""/>
                        </div>
                    </div>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h4 className={s.FlexTitle}>
                                {t("ethics-title2")}
                            </h4>
                            <p>
                                {t("ethics-text2")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={ComplianceImg} alt="Compliance with Legal Norms and Standards"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h4 className={s.FlexTitle}>
                                {t("ethics-title3")}
                            </h4>
                            <p>
                                {t("ethics-text3")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={CulturalDifImg} alt="Respect for Cultural Differences"/>
                        </div>
                    </div>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h4 className={s.FlexTitle}>
                                {t("ethics-title4")}
                            </h4>
                            <p>
                                {t("ethics-text4")}

                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={ProtectionImg} alt="Confidentiality of Information"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h4 className={s.FlexTitle}>
                                {t("ethics-title5")}
                            </h4>
                            <p>
                                {t("ethics-text5")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={SupportImg} alt="Support and Maintenance"/>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}