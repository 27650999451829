import s from './Vision.module.css'
import {useTranslation} from "react-i18next";
export const Vision = () => {

    const {t} = useTranslation()

    return  (
      <div className={s.Vision}>
          <div className={s.VisionBG}>
              <h3>{t("vision")}</h3>
          </div>
          <div className="container">
              <div className={s.Wrapper}>
                  <h4 id="vision" className={`${s.visionTitle} ${s.aboutTitles}`}>
                      {t("vision")}
                  </h4>
                  <h5 className={s.visionSubtitle}>{t("vision-title")}</h5>
                  <p className={s.visionDesc}>
                      {t("vision-desc")}
                  </p>
              </div>
          </div>

      </div>
  )
}