import s from './Network.module.css'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const Network = () => {

    const {t} = useTranslation()

    return (
        <div className={s.Network}>
            <div className={s.NetworkBG}>
                <h3>{t("network")}</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={s.Text}>
                        <p className={s.Desc}>
                            {t("network-text1")} <span>{t("kiev")}</span>, <span>{t("warsaw")}</span>, <span>{t("bishkek")}</span>, and <span>
                            {t("delhi")}</span> {t("network-text1.5")} <Link to={"/Relationship-based"}>{t("network-link")}</Link>{t("network-text1.9")}
                        </p>
                    </div>

                </div>
            </div>

        </div>
    )
}