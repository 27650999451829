import './Header.css'
import {Link} from "react-router-dom";
import logo from '../../assets/img/logo.png'
import aboutUsLogo from '../../assets/img/AboutUsLogo.jpg'
import employers from '../../assets/img/employers.jpg'
import closeBtn from '../../assets/img/close-btn.svg'
import burgerMenu from '../../assets/img/burger-menu.svg'
import OurValues from '../../assets/img/OurValues.jpg'
import CoreServices from '../../assets/img/CoreServices.webp'
import CorporateSupport from '../../assets/img/CorporateSupport.webp'
import Hospitality from '../../assets/img/Hospitality.webp'
import Manufacturing from '../../assets/img/Manufacturing.webp'
import Construction from '../../assets/img/Construction.webp'
import avrasyaPortal from '../../assets/img/avrasyaPortal.png'
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {useEffect, useRef, useState} from "react";


export const Header = () => {

    const {t} = useTranslation()

    const menuRef = useRef(null); // создаем реф для меню

    const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);

    // Синхронизация состояния с текущим языком при первой загрузке
    useEffect(() => {
        setSelectedLanguage(i18next.language);
    }, []);
    const changeLanguage = (language) => {
        i18next.changeLanguage(language);
        setSelectedLanguage(language); // обновляем состояние
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Проверяем, клик был вне меню или нет
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                // Если вне, то сбрасываем активное состояние меню
                document.getElementById('menu-btn').checked = false; // закрываем меню
            }
        };

        // Добавляем обработчик события клика на документ
        document.addEventListener('click', handleClickOutside);

        // Очищаем обработчик при размонтировании компонента
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <header className="header">
            <nav ref={menuRef}>
                <div className="wrapper">
                    <Link to={'/'} className="logo"><img src={logo} alt="logo"/> <p>{t("logo_f")} <br/><span>{t("logo_s")}</span>
                    </p></Link>
                    <input type="radio" name="slider" id="menu-btn"/>
                    <input type="radio" name="slider" id="close-btn"/>
                    <ul className="nav-links">
                        <label htmlFor="close-btn" className="btn close-btn">
                            <i className="fas fa-times"><img src={closeBtn} alt=""/></i></label>
                        <li>
                            <Link className="desktop-item" to={"/aboutUs"}>{t("about-us")}</Link>
                            <input type="checkbox" id="aboutUs"/>
                            <label htmlFor="aboutUs" className="mobile-item">{t("about-us")}    </label>
                            <div className="mega-box">
                                <div className="content">
                                    <div className="row">
                                        <img src={aboutUsLogo} alt=""/>
                                    </div>
                                    <div className="row">
                                        <header>{t("about-avrasya")}</header>
                                        <ul className="mega-links">
                                            <li><Link to={"/Purpose"}>{t("purpose")}</Link></li>
                                            <li><Link to={"/History"}>{t("history")}</Link></li>
                                            <li><Link to={"/Vision"}>{t("vision")}</Link></li>
                                            <li><Link to={"/Mission"}>{t("mission")}</Link></li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <img src={OurValues} alt="OurValues"/>
                                    </div>
                                    <div className="row">
                                        <header>{t("our-values")}</header>
                                        <ul className="mega-links">
                                            <li><Link to={"/Ethics-Compliance"}>{t("ethics-compliance")}</Link></li>
                                            <li><Link to={"/Relationship-based"}>{t("relationship-based")}</Link></li>
                                            <li><Link to={"/CodeOfConduct"}>{t("Code-of-conduct")}</Link></li>
                                            <li><Link to={"/CorporateResponsibility"}>{t("corporate-responsibility")}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <Link className="desktop-item" to={"/Services"}>{t("services")}</Link>
                            <input type="checkbox" id="services"/>
                            <label htmlFor="services" className="mobile-item">{t("services")}</label>
                            <div className="mega-box">
                                <div className="content">
                                    <div className="row">
                                        <img src={CoreServices} alt="CoreServices"/>
                                    </div>
                                    <div className="row">
                                        <header>{t("core-service")}</header>
                                        <ul className="mega-links">
                                            <li><Link to={"/Recruitment"}>{t("recruitment")}</Link></li>
                                            <li><Link to={"/StaffingManagement"}>{t("staffing-management")}</Link></li>
                                            <li><Link to={"/360Hiring"}>{t("360hiring")}</Link></li>

                                        </ul>
                                    </div>
                                    <div className="row">
                                        <ul className="mega-links">
                                            <img src={CorporateSupport} alt="CorporateSupport"/>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header>{t("corporate-support")}</header>
                                        <ul className="mega-links">
                                            <li><Link to={"/CostReduction"}>{t("cost-reduction")}</Link></li>
                                            <li><Link to={"/StaffAccommodation"}>{t("staff-accommodation")}</Link></li>
                                            <li><Link to={"/HrConsultancy"}>{t("hr-consultancy")}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <Link className="desktop-item" to={'/OurReferences'}>{t("references")}</Link>
                            <input type="checkbox" id="references"/>
                            <label htmlFor="references" className="mobile-item">{t("references")}</label>
                            <div className="mega-box">
                                <div className="content">

                                    <div className="row">
                                        <header className="references-header">{t("hospitality")}</header>
                                        <ul className="mega-links">
                                            <Link to={'/Hospitality'}>
                                                <img src={Hospitality} alt="Hospitality"/>
                                            </Link>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header className="references-header">{t("manufacturing")}</header>
                                        <ul className="mega-links">
                                            <Link to={'/Manufacturing'}>
                                                <img src={Manufacturing} alt="Manufacturing"/>
                                            </Link>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header className="references-header">{t("construction")}</header>
                                        <ul className="mega-links">
                                            <Link to={'/Construction'}>
                                                <img src={Construction} alt="Construction"/>
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <Link className="desktop-item" to={"#"}>{t("corporate")}</Link>
                            <input type="checkbox" id="corporate"/>
                            <label htmlFor="corporate" className="mobile-item">{t("corporate")}</label>
                            <div className="mega-box">
                                <div className="content">
                                    <div className="row">
                                        <img src={employers} alt=""/>
                                    </div>
                                    <div className="row">
                                        <header>{t("employers")}</header>
                                        <ul className="mega-links">
                                            <li><Link to={"/Solutions"}>{t("solutions")}</Link></li>
                                            <li><Link to={"/DemandForm"}>{t("demand-form")}</Link></li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header>{t("agent")}</header>
                                        <ul className="mega-links">
                                            <li><Link to={"/Network"}>{t("network")}</Link></li>
                                            <li><Link to={"/AgentForm"}>{t("agent-form")}</Link></li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <ul className="mega-links">
                                            <li className="avrasya-portal">
                                                <Link to={'https://portal.avrasyaconsulting.com/login'}><img src={avrasyaPortal} alt="Portal"/></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <Link className="desktop-item" to={"#"}>{t("contacts")}</Link>
                            <input type="checkbox" id="contacts"/>
                            <label htmlFor="contacts" className="mobile-item">{t("contacts")}</label>
                            <ul className="drop-menu">
                                <li><Link to={"/Individuals"}>{t("individuals")}</Link></li>
                                <li><Link to={"/Locations"}>{t("locations")}</Link></li>
                            </ul>
                        </li>
                        <li>
                            <select className="language-select" name="language" id="" value={selectedLanguage} onChange={(e) => changeLanguage(e.target.value)} >
                                <option value="en">English</option>
                                <option value="tr">Türkçe</option>
                                <option value="ru">Русский</option>
                            </select>
                        </li>
                    </ul>
                    <label htmlFor="menu-btn" className="btn menu-btn"><img src={burgerMenu} alt=""/><i
                        className="fas fa-bars"></i></label>
                </div>
            </nav>
        </header>
    )
}