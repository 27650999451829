import s from './Purpose.module.css'
import {useTranslation} from "react-i18next";
export const Purpose = ()=>{

    const {t} = useTranslation()


    return  (
    <div className={s.Purpose}>
        <div className={s.PurposeBG}>
            <h3>{t("purpose")}</h3>
        </div>
        <div className="container">
            <div className={s.Wrapper}>
                <h4 id="purpose" className={`${s.purposeTitle} ${s.aboutTitles}`}>
                    {t("purpose")}
                </h4>

                <p className={s.purposeInfo}>
                    <span>{t("purposeInfo1_title")}</span>
                    {t("purposeInfo1_text")}
                </p>
                <p className={s.purposeInfo}>
                    <span>{t("purposeInfo2_title")}</span>
                    {t("purposeInfo2_text")}
                </p>
                <p className={s.purposeInfo}>
                    <span>{t("purposeInfo3_title")}</span>
                    {t("purposeInfo3_text")}
                </p>

                <p className={s.purposeInfo}>
                    <span>{t("purposeInfo4_title")}</span>
                    {t("purposeInfo4_text")}
                </p>

                <p className={s.purposeInfo}>
                    <span>{t("purposeInfo5_title")}</span>
                    {t("purposeInfo5_text")}
                </p>

                <p className={s.purposeInfo}>
                    <span>{t("purposeInfo6_title")}</span>
                    {t("purposeInfo6_text")}
                </p>

                <p className={s.purposeInfo}>
                    <span>{t("purposeInfo7_title")}</span>
                    {t("purposeInfo7_text")}
                </p>

                <p className={s.purposeInfo}>
                    <span>{t("purposeInfo8_title")}</span>
                    {t("purposeInfo8_text")}
                </p>

                <p className={s.purposeInfo}>
                    <span>{t("purposeInfo9_title")}</span>
                    {t("purposeInfo9_text")}
                </p>

                <p className={s.purposeInfo}>
                    <span>{t("purposeInfo10_title")}</span>
                    {t("purposeInfo10_text")}
                </p>
            </div>
        </div>
    </div>


    )
}