import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import Loader from "./components/Loader/Loader";
import './i18n'; // Подключение файла с конфигурацией i18n


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Suspense fallback={<Loader/>}>
            <App/>
        </Suspense>
    </BrowserRouter>
);

