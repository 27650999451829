import s from './CorporateResponsibility.module.css'
import {Link} from "react-router-dom";
import EnvironmentImg from '../../assets/img/CorporateResponsibility/EnvironmentImg.webp'
import EthicalImg from '../../assets/img/CorporateResponsibility/EthicalImg1.webp'
import CommunityImg from '../../assets/img/CorporateResponsibility/CommunityImg.webp'
import MentorImg from '../../assets/img/CorporateResponsibility/MentorImg.webp'
import {useTranslation} from "react-i18next";
export const CorporateResponsibility = () => {

    const {t} = useTranslation()

    return (
        <div className={s.CorporateResponsibility}>
            <div className={s.CorporateResponsibilityBG}>
                <h3>{t("corporate-responsibility")}</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("corporate-responsibility-title1")}</h5>
                            <p>
                                {t("corporate-responsibility-text1")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={EnvironmentImg} alt="Environment Img"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("corporate-responsibility-title2")}</h5>
                            <p>
                                {t("corporate-responsibility-text2.1")} <Link className={s.LinkUnderline} to={'/CodeOfConductPdf'}>{t("Code-of-conduct")}</Link> {t("corporate-responsibility-text2.5")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={EthicalImg} alt="Ethical responsibility"/>
                        </div>
                    </div>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("corporate-responsibility-title3")}</h5>
                            <p>
                                {t("corporate-responsibility-text3")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={CommunityImg} alt="Philanthropic Responsibility"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("corporate-responsibility-title4")}</h5>
                            <p>
                                {t("corporate-responsibility-text4")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={MentorImg} alt="Economic responsibility"/>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}