import s from './Services.module.css'
import {Link} from "react-router-dom";
import CostReduction from "../../assets/img/CostReduction/CostReductionBG.webp";
import StaffAccommodation from "../../assets/img/StaffAccommodation/StaffAccommodationBG.jpg";
import HrConsultancy from "../../assets/img/HrConsultancy/HrConsultancyBG.webp";
import Recruitment from '../../assets/img/Recruitment/RecruitmentBG.webp'
import StaffingManagement from '../../assets/img/StaffManagement/StaffManagementBG.webp'
import Hiring from '../360Hiring/360Hiring.webp'
import {useTranslation} from "react-i18next";

export const Services = () => {

    const {t} = useTranslation()

    return (
        <div className={s.Services}>
            <div className={s.ServicesBG}>
                <h3>{t("services")}</h3>
            </div>
            <div className="container">
                <h4 className={s.title}>{t("core-service")}</h4>
                <div className={s.parent}>
                    <Link to={'/CostReduction'} className={`${s.CostReduction} ${s.div1}`}>
                        <div>
                            <img src={CostReduction} alt="Cost Reduction"/>
                            <p>{t("cost-reduction")}</p>
                        </div>
                    </Link>
                    <Link to={'/StaffAccommodation'} className={`${s.StaffAccommodation} ${s.div2}`}>
                        <div>
                            <img src={StaffAccommodation} alt="Staff Accommodation"/>
                            <p>{t("staff-accommodation")}</p>
                        </div>
                    </Link>
                    <Link to={'/HrConsultancy'} className={`${s.HrConsultancy} ${s.div3}`}>
                        <div>
                            <img src={HrConsultancy} alt="HR Consultancy"/>
                            <p>{t("hr-consultancy")}</p>
                        </div>
                    </Link>

                </div>

                <h4 className={s.title}>{t("corporate-support")}</h4>

                <div className={s.parent}>
                    <Link to={'/Recruitment'} className={`${s.CostReduction} ${s.div1}`}>
                        <div>
                            <img src={Recruitment} alt="Recruitment"/>
                            <p>{t("recruitment")}</p>
                        </div>
                    </Link>
                    <Link to={'/StaffingManagement'} className={`${s.StaffAccommodation} ${s.div2}`}>
                        <div>
                            <img src={StaffingManagement} alt="StaffingManagement"/>
                            <p>{t("staffing-management")}</p>
                        </div>
                    </Link>
                    <Link to={'/360Hiring'} className={`${s.HrConsultancy} ${s.div3}`}>
                        <div>
                            <img src={Hiring} alt="Hiring"/>
                            <p>{t("360hiring")}</p>
                        </div>
                    </Link>

                </div>
            </div>

        </div>
    )
}