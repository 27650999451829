import React from "react";
import SwiperCore, {EffectCoverflow, Pagination, Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import './Slider.css'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

SwiperCore.use([EffectCoverflow, Pagination, Autoplay]);


export const Slider = () => {

    const {t} = useTranslation()

    return (
        <div className="slider-wrapper">
            <Swiper
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                pagination={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='swiper-background swiper-background_1'>
                        <div className="swiper-text">
                            <h3 className="swiper-title">{t("our-references")}</h3>
                            <p className="swiper-desc">{t("our-references-desc")}</p>
                            <Link className="swiper-link" to={'/OurReferences'}>{t("learn-more")} </Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-background swiper-background_2'>
                        <div className="swiper-text">
                            <h3 className="swiper-title">{t("360hiring")}</h3>
                            <p className="swiper-desc">{t("360hiring-desc")}</p>
                            <Link className="swiper-link" to={'/360Hiring'}>{t("learn-more")}</Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-background swiper-background_3'>
                        <div className="swiper-text">
                            <h3 className="swiper-title">{t("our-services")} </h3>
                            <p className="swiper-desc">{t("our-services-desc")}</p>
                            <Link className="swiper-link" to={'/Services'}>{t("learn-more")}</Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-background swiper-background_4'>
                        <div className="swiper-text">
                            <h3 className="swiper-title">{t("opportunities")}</h3>
                            <p className="swiper-desc">{t("opportunities-desc")}</p>
                            <Link className="swiper-link" to={'/Portal'}>{t("learn-more")}</Link>
                        </div>

                    </div>
                </SwiperSlide>

            </Swiper>
        </div>
    );
};