import s from './CodeOfConduct.module.css'
import {Link} from "react-router-dom";
import LinkImg from './LinkImg.webp'
import {useTranslation} from "react-i18next";
export const CodeOfConduct = () => {

    const {t} = useTranslation()

    return (
        <div className={s.CodeOfProduct}>
            <div className={s.CodeOfProductBG}>
                <h3>{t("Code-of-conduct")}</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={s.Text}>
                        <h4 className={s.Title}>
                            {t("code-of-conduct-title")}
                        </h4>
                        <p className={s.desc}>
                            {t("code-of-conduct-text")}
                        </p>
                        <h4 className={s.subtitle}>
                            {t("code-of-conduct-subtitle")}
                        </h4>
                        <p className={s.subDesc}>
                            {t("code-of-conduct-subtext")}
                        </p>
                        <ul className={s.conductList}>
                            <li className={s.conductItem}>
                                {t("code-of-conduct-step1")}
                            </li>
                            <li className={s.conductItem}>
                                {t("code-of-conduct-step2")}
                            </li>
                            <li className={s.conductItem}>
                                {t("code-of-conduct-step3")}
                            </li>
                            <li className={s.conductItem}>
                                {t("code-of-conduct-step4")}
                            </li>
                            <li className={s.conductItem}>
                                {t("code-of-conduct-step5")}
                            </li>
                            <li className={s.conductItem}>
                                {t("code-of-conduct-step6")}
                            </li>
                        </ul>
                    </div>
                    <Link className={s.pdfLink} to={"/CodeOfConductPdf"}><img className={s.linkImg} src={LinkImg} alt="CodeOfConductPDF"/></Link>

                </div>
            </div>

        </div>
    )
}