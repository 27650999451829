import s from './Hiring.module.css'
import TalentDatabaseImg from './TalentDatabaseImg.webp'
import CandidatesImg from './CandidatesImg.webp'
import HiringProcessImg from './HiringProcessImg.webp'
import EmploymentSupportImg from './EmploymentSupportImg.webp'
import {useTranslation} from "react-i18next";
export const Hiring = () => {

    const {t} = useTranslation()

    return (
        <div className={s.Hiring}>
            <div className={s.HiringBG}>
                <h3>{t("360hiring")}</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("360hiring-title1")}</h5>
                            <p>
                                {t("360hiring-text1")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={TalentDatabaseImg} alt="Talent Database"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("360hiring-title2")}</h5>
                            <p>
                                {t("360hiring-text2")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={CandidatesImg} alt="Candidates"/>
                        </div>
                    </div>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("360hiring-title3")}</h5>
                            <p>
                                {t("360hiring-text3")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={HiringProcessImg} alt="Hiring Process"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("360hiring-title4")}</h5>
                            <p>
                                {t("360hiring-text4")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={EmploymentSupportImg} alt="Employment Support"/>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}