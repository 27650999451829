import s from './AboutUs.module.css'
import purpose from './purpose.webp'
import history from './history.webp'
import vision from './vision.webp'
import mission from './mission.webp'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const AboutUs = () => {

    const {t} = useTranslation()


    return (
        <div className={s.about}>
            <div className={s.aboutBG}>
                <h3>{t("about-us")}</h3>
            </div>
            <div className="container">
                <div className={s.aboutWrapper}>
                    <div className={s.aboutText}>
                        <p className={s.aboutTitle}>
                            {t("about-us-text")}
                        </p>
                    </div>
                </div>

                <div className={s.parent}>
                    <Link to={'/Purpose'}  className={`${s.purpose} ${s.div1}`}>
                        <div>
                            <img src={purpose} alt=""/>
                            <p>{t("purpose")}</p>
                        </div>
                    </Link>
                    <Link to={'/History'} className={`${s.history} ${s.div2}`}>
                        <div >
                            <img src={history} alt=""/>
                            <p>{t("history")}</p>
                        </div>
                    </Link>
                    <Link to={'/Vision'} className={`${s.vision} ${s.div3}`}>
                        <div>
                            <img src={vision} alt=""/>
                            <p>{t("vision")}</p>
                        </div>
                    </Link>
                    <Link to={'/Mission'} className={`${s.mission} ${s.div4}`}>
                        <div >
                            <img src={mission} alt=""/>
                            <p>{t("mission")}</p>
                        </div>
                    </Link>
                </div>

            </div>
        </div>


)
}