import s from "../CostReduction/CostReduction.module.css";
import infographic from "../../assets/img/CorporateSupport/CostReductionGraphic.webp";
import {useTranslation} from "react-i18next";
export const CostReduction = () => {

    const {t} = useTranslation()

    return (
      <div>
          <div className={s.CostReduction}>
              <div className={s.CostReductionBG}>
                  <h3>{t("cost-reduction")}</h3>
              </div>
          </div>
          <div className="container">
              <div className={s.Wrapper}>
                  <div className={s.Text}>
                      <h4 className={s.Title}>{t("cost-reduction-title")}</h4>
                      <img className={s.costReductionImg} src={infographic} alt="infographic"/>
                  </div>
              </div>
          </div>
      </div>
  )
}