import s from './Locations.module.css'
import turkeyOffice from '../../assets/img/Locations/turkeyOffice.png'
import ukraineOffice from '../../assets/img/Locations/UkraineOffice.png'
import bishkekOffice from '../../assets/img/Locations/BishkekOffice.png'
import polandOffice from '../../assets/img/Locations/PolandOffice.png'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const Locations = () => {

    const {t} = useTranslation()

    return (
        <div className={s.Locations}>
            <div className={s.LocationsBG}>
                <h3>{t("locations")}</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={s.mainOffice}>
                        <h4 className={s.Title}>{t("locations-main")}</h4>
                        <img src={turkeyOffice} alt="turkeyOffice"/>
                        <p className={s.city}>{t("ankara")}</p>
                        <p className={s.address}><Link className={s.addressLink} to={"https://maps.app.goo.gl/tsXEudDJRJmDcwfs7"}>{t("locations-ankara-address")}</Link> </p>
                        <p className={s.phone}>Tel: +90 312 543 83 38</p>
                        <p className={s.email}>Email: info@avrasyaconsulting.com</p>
                    </div>
                    <h4 className={s.regTitle}>{t("locations-region")}</h4>
                    <div className={s.regionalOffices}>
                        <div className={s.regOffice}>
                            <p className={s.regOfficeCity}>{t("kiev")}</p>
                            <img src={ukraineOffice} alt="ukraineOffice"/>
                            <p className={s.regOfficeAddress}>
                                <Link className={s.addressLink} to={"https://maps.app.goo.gl/khT1J6rnxnTvjRqf6"}>{t("locations-kiev-address")}</Link> </p>
                        </div>
                        <div className={s.regOffice}>
                            <p className={s.regOfficeCity}>{t("bishkek")}</p>
                            <img src={bishkekOffice} alt="bishkekOffice"/>
                            <p className={s.regOfficeAddress}>
                               <Link className={s.addressLink} to={"https://2gis.kg/bishkek/firm/70000001084419811?floor=2&m=74.609298%2C42.857144%2F19.68"}>{t("locations-bishkek-address")}</Link> </p>
                        </div>
                        <div className={s.regOffice}>
                            <p className={s.regOfficeCity}>{t("warsaw")}</p>
                            <img src={polandOffice} alt="polandOffice"/>
                            <p className={s.regOfficeAddress}><Link className={s.addressLink} to={"https://maps.app.goo.gl/1iZHFZ5MFaQ5XpBu8"}>{t("locations-warsaw-address")}</Link> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}