import s from '../Construction/Construction.module.css'
import MetGun from '../../assets/img/OurReferences/Construction/Met-Gun.png'
import Fernas from '../../assets/img/OurReferences/Construction/Fernas.png'
import EzeInsaat from '../../assets/img/OurReferences/Construction/EzeInsaat.png'
import Celikler from '../../assets/img/OurReferences/Construction/Celikler.jpg'
import Ugular from '../../assets/img/OurReferences/Construction/UĞURAL.png'
export const Construction = () => {
    return (
        <div className={s.Construction}>
            <div className={s.ConstructionBG}>
                <h3>Construction</h3>
            </div>
            <div className={s.Wrapper}>
                <div className="container">
                    <ul className={s.List}>
                        <li className={s.Item}>
                            <img src={MetGun} alt={'MetGun'}/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={Fernas} alt="Fernas"/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={EzeInsaat} alt="EzeInsaat"/>
                            <p className={s.Desc}></p>
                        </li>

                        <li className={s.Item}>
                            <img src={Celikler} alt="Celikler"/>
                            <p className={s.Desc}></p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}