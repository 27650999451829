import s from './Mission.module.css'
import {useTranslation} from "react-i18next";

export const Mission = () => {

    const {t} = useTranslation()

    return (
        <div className={s.Mission}>
            <div className={s.MissionBG}>
                <h3>{t("mission")}</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <h4 id={'mission'} className={`${s.missonTitle} ${s.aboutTitles}`}>
                        {t("mission")}
                    </h4>
                    <p className={s.missionDesc}>
                        {t("mission-desc")}
                    </p>
                    <p className={s.missionInfo}>
                        <span>{t("mission-title1")}</span>
                        {t("mission-text1")}
                    </p>
                    <p className={s.missionInfo}>
                        <span>{t("mission-title2")}</span>
                        {t("mission-text2")}
                    </p>
                    <p className={s.missionInfo}>
                        <span>{t("mission-title3")}</span>
                        {t("mission-text3")}
                    </p>
                </div>
            </div>
        </div>
    )
}