import {Slider} from "../../components/Slider/Slider";
import {Link} from "react-router-dom";
import careerPath from "../../assets/img/career.jpg";
import project from "../../assets/img/project.jpg";
import report from "../../assets/img/report.jpg";
import news from "../../assets/img/news.jpg";
import news2 from "../../assets/img/news2.jpg";
import {MediaCard} from "../../components/MediaCard/MediaCard";
import instagram from "../../assets/img/instagram.webp";
import linkedin from "../../assets/img/linkedin.jpg";
import './Main.css'
import {useTranslation} from "react-i18next";

export const Main = () => {

    const {t} = useTranslation()

    return (
        <div className="main">
            <Slider/>
            <div className="about">
                <div className="container">
                    <div className="about__wrapper">
                        <div className="about__desc">{t("about-desc")}
                        </div>
                        <div className="about__link"><Link to={'/aboutUs'}>{t("about-us")}<span>❯</span></Link></div>

                    </div>
                </div>
                <div className="parent">
                    <div className="parent__wrapper">
                        <Link className="career-path div1" to={'/Relationship-based'}>
                            <div>
                                <img src={careerPath} alt=""/>
                                <div className="grid__text">
                                    <span>{t("relationship-based")}</span>
                                    <p>{t("relationship-based-desc")}</p>
                                </div>
                            </div>
                        </Link>
                        <Link className="project div2" to={'/Ethics-Compliance'}>
                            <div>
                                <img src={project} alt=""/>
                                <div className="grid__text">
                                    <span>{t("ethics-compliance")}</span>
                                    <p>{t("ethics-compliance-desc")}</p>
                                </div>
                            </div>
                        </Link>
                        <Link className="report div3" to={'/Network'}>
                            <div>
                                <img src={report} alt=""/>
                                <div className="grid__text">
                                    <span>{t("network")}</span>
                                    <p>{t("network")}</p>
                                </div>
                            </div>
                        </Link>
                        <Link className="news div4" to={'/360Hiring'}>
                            <div>
                                <img src={news} alt=""/>
                                <div className="grid__text">
                                    <span>{t("360hiring")}</span>
                                    <p>{t("360hiring-mainDesc")}</p>
                                </div>
                            </div>
                        </Link>
                        <Link className="news-2 div5" to={'https://portal.avrasyaconsulting.com/login'}>
                            <div>
                                <img src={news2} alt=""/>
                                <div className="grid__text">
                                    <span>{t("avrasya-portal")}</span>
                                    <p>{t("avrasya-portal-desc")}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="media">
                    <div className="container">
                        <div className="media__inner">
                            <MediaCard img={linkedin} title={t("linkedin-title")}
                                       desc={''}
                                       mediaTitle={"Linkedin"}
                                       link={"https://www.linkedin.com/company/avrasya-consulting"}
                                       linkTitle={t("folow-us")}

                            />
                            <MediaCard img={instagram} title={t("instagram-title")}
                                       desc={''}
                                       mediaTitle={"Instagram"}
                                       link={"https://www.instagram.com/myvi.sa/"}
                                       linkTitle={t("folow-us")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
