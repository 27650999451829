import s from './Portal.module.css'
import {Link} from "react-router-dom";
import portalImg from '../../assets/img/avrasyaPortal.png'

export const Portal = () => {
    return (
                <div className={s.loginFormBD}>
                    <div className={s.formWrapper}>
                        <div className={s.formContainer}>
                            <img className={s.portalImg} src={portalImg} alt="Please Login"/>
                            <form>
                                <div className={s.formControl}>
                                    <input type="text" required/>
                                        <label><span>Email</span> </label>
                                </div>

                                <div className={s.formControl}>
                                    <input type="password" required/>
                                        <label><span>Password</span></label>
                                </div>
                                <button className={s.loginBtn}>Login</button>
                            </form>
                        </div>
                    </div>
                </div>
    )
}