import s from '../Hospitality/Hospitality.module.css'
import Rixos from '../../assets/img/OurReferences/Hospitality/Rixos.png'
import Raddison from '../../assets/img/OurReferences/Hospitality/Radisson.png'
import DoubleTree from '../../assets/img/OurReferences/Hospitality/DoubleTree.png'
import XafiraDeluxe from '../../assets/img/OurReferences/Hospitality/XafiraDeluxe.png'
export const Hospitality = () => {
    return (
        <div className={s.Hospitality}>
            <div className={s.HospitalityBG}>
                <h3>Hospitality</h3>
            </div>
            <div className={s.Wrapper}>
                <div className="container">
                    <ul className={s.List}>
                        <li className={s.Item}>
                            <img src={Rixos} alt={'Rixos'}/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={Raddison} alt="Raddison"/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={DoubleTree} alt="DoubleTree"/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={XafiraDeluxe} alt=" start"/>
                            <p className={s.Desc}></p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}