import s from './Recruitment.module.css'
import MarketTrendsImg from  '../../assets/img/Recruitment/MarketTrendsImg.webp'
import TalentMatchImg from  '../../assets/img/Recruitment/TalentMatchImg.webp'
import BusinessNetworkImg from '../../assets/img/Recruitment/BusinessNetworkImg.webp'
import {useTranslation} from "react-i18next";
export const Recruitment = () => {

    const {t} = useTranslation()

    return (
        <div className={s.Recruitment}>
            <div className={s.RecruitmentBG}>
                <h3>{t("recruitment")}</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={s.Text}>
                        <div className={s.TextInner}>
                            <p className={s.Desc}>
                                {t("recruitment-desc1")}
                            </p>
                            <p className={s.DescSec}>
                                {t("recruitment-desc2")}
                            </p>
                            <p className={s.Desc}>
                                {t("recruitment-desc3")}
                            </p>
                            <p className={s.DescSec}>
                                {t("recruitment-desc4")}
                            </p>
                            <p className={s.Desc}>
                                {t("recruitment-desc5")}
                            </p>
                        </div>
                        <div className={s.TextImgs}>
                            <img src={MarketTrendsImg} alt="Market Trends"/>
                            <img src={TalentMatchImg} alt="Taletn Match"/>
                            <img src={BusinessNetworkImg} alt="Business Network"/>
                            </div>
                    </div>

                </div>
            </div>

        </div>
    )
}