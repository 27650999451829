import s from './History.module.css'
import {useTranslation} from "react-i18next";
export const History = () => {

    const {t} = useTranslation()

    return (
      <div className={s.History}>
          <div className={s.HistoryBG}>
              <h3>{t("history")}</h3>
          </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <h4 id="history" className={`${s.historyTitle} ${s.aboutTitles}`}>
                        {t("history")}
                    </h4>
                    <p className={s.historyDesc}>
                        {t("history-desc1")}
                    </p>
                    <p className={s.historyDesc}>
                        {t("history-desc2")}
                    </p>
                </div>
            </div>
      </div>
  )

}