import s from "../HrConsultancy/HrConsultancy.module.css";
import Bg from '../../assets/img/HrConsultancy/HrConsultancyBG.webp'
export const HrConsultancy = () => {
  return (
      <div>
          <div className={s.HrConsultancyBG}>
              <img src={Bg} alt="HR Consultancy"/>
          </div>
      </div>
  )
}