import s from '../Manufacturing/Manufacturing.module.css'
import Guven from '../../assets/img/OurReferences/Manufacturing/Guven.png'
import Hestas from '../../assets/img/OurReferences/Manufacturing/Hestas.png'
import Titan from '../../assets/img/OurReferences/Manufacturing/Titan.png'
import HermaksaMakina from '../../assets/img/OurReferences/Manufacturing/HermaksaMakina.png'
import Isiklar from '../../assets/img/OurReferences/Manufacturing/Isiklar.png'
import RoledaMakina from '../../assets/img/OurReferences/Manufacturing/Roleda Makina.png'
import Schonline from '../../assets/img/OurReferences/Manufacturing/schonline_logo.jpg'
import YeniAnadolu from '../../assets/img/OurReferences/Manufacturing/yeni Anadolu.jpg'
import Epotek from '../../assets/img/OurReferences/Manufacturing/Epotek.png'
import Nprokon from '../../assets/img/OurReferences/Manufacturing/mtf_prokon_cover.jpeg'
import Oytun from '../../assets/img/OurReferences/Manufacturing/Oytun.png'
import Hydropower from '../../assets/img/OurReferences/Manufacturing/Hydropower.jpeg'
import Aypek from '../../assets/img/OurReferences/Manufacturing/aypek.jpg'
import Makis from '../../assets/img/OurReferences/Manufacturing/makis.jpg'
import Turkuaz from '../../assets/img/OurReferences/Manufacturing/turkuaz.jpg'
import SahTavuk from '../../assets/img/OurReferences/Manufacturing/SahTavuk.jpg'

export const Manufacturing = () => {
    return (
        <div className={s.Manufacturing}>
            <div className={s.ManufacturingBG}>
                <h3>Manufacturing</h3>
            </div>
            <div className={s.Wrapper}>
                <div className="container">
                    <ul className={s.List}>
                        <li className={s.Item}>
                            <img src={Guven} alt={'Guven'}/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={Schonline} alt={'Schonline'}/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={YeniAnadolu} alt={'YeniAnadolu'}/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={Epotek} alt={'Epotek'}/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={Nprokon} alt={'Nprokon'}/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={Oytun} alt={'Oytun'}/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={Hydropower} alt={'Hydropower'}/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={Hestas} alt="Hestas"/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={Titan} alt="Titan"/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={HermaksaMakina} alt="HermaksaMakina"/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={Isiklar} alt="Isiklar"/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={RoledaMakina} alt="RoledaMakina"/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={Aypek} alt="Aypek"/>
                            <p className={s.Desc}></p>
                        </li>

                        <li className={s.Item}>
                            <img src={Makis} alt="Makis"/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={Turkuaz} alt="Turkuaz"/>
                            <p className={s.Desc}></p>
                        </li>
                        <li className={s.Item}>
                            <img src={SahTavuk} alt="SahTavuk"/>
                            <p className={s.Desc}></p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}