import './App.css';
import {Header} from "./components/Header/Header";
import {Footer} from "./components/Footer/Footer";
import {Route, Routes} from "react-router-dom";
import {Main} from "./pages/Main/Main";
import {AboutUs} from "./pages/AboutUs/AboutUs";
import {EthicsCompliance} from "./pages/EthicsCompliance/EthicsCompliance";
import {RelationshipBased} from "./pages/RelationshipBased/RelationshipBased";
import {CodeOfConduct} from "./pages/CodeOfConduct/CodeOfConduct";
import {CorporateResponsibility} from "./pages/CorporateResponsibility/CorporateResponsibility";
import {Services} from "./pages/Services/Services";
import {Recruitment} from "./pages/Recruitment/Recruitment";
import {StaffingManagement} from "./pages/StaffingManagement/StaffingManagement";
import {Hiring} from "./pages/360Hiring/Hiring";
import {Hospitality} from "./pages/Hospitality/Hospitality";
import {Manufacturing} from "./pages/Manufacturing/Manufacturing";
import {Construction} from "./pages/Construction/Construction";
import {Solutions} from "./pages/Solutions/Solutions";
import {Network} from "./pages/Network/Network";
import {DemandForm} from "./pages/DemandForm/DemandForm";
import {AgentForm} from "./pages/AgentForm/AgentForm";
import {Individuals} from "./pages/Individuals/Individuals";
import {Locations} from "./pages/Locations/Locations";
import {Portal} from "./pages/Portal/Portal";
import {OurReferences} from "./pages/OurReferences/OurReferences";
import {Purpose} from "./pages/Purpose/Purpose";
import {History} from "./pages/History/History";
import {Mission} from "./pages/Mission/Mission";
import {Vision} from "./pages/Vision/Vision";
import {CodeOfConductPdf} from "./pages/CodeOfConductPdf/CodeOfConductPdf";
import {CostReduction} from "./pages/CostReduction/CostReduction";
import {StaffAccommodation} from "./pages/StaffAccommodation/StaffAccommodation";
import {HrConsultancy} from "./pages/HrConsultancy/HrConsultancy";
import Loader from "./components/Loader/Loader";

function App() {
    return (

        <div className="app-wrapper">
            <Header/>
            <Routes>
                <Route path={'/'} element={<Main/>}></Route>
                <Route path={'/aboutUs'} element={<AboutUs/>}></Route>
                <Route path={'/Mission'} element={<Mission/>}></Route>
                <Route path={'/History'} element={<History/>}></Route>
                <Route path={'/Purpose'} element={<Purpose/>}></Route>
                <Route path={'/Vision'} element={<Vision/>}></Route>
                <Route path={'/Ethics-Compliance'} element={<EthicsCompliance/>}></Route>
                <Route path={'/Relationship-based'} element={<RelationshipBased/>}></Route>
                <Route path={'/CodeOfConduct'} element={<CodeOfConduct/>}></Route>
                <Route path={'/CorporateResponsibility'} element={<CorporateResponsibility/>}></Route>
                <Route path={'/CodeOfConductPdf'} element={<CodeOfConductPdf/>}></Route>
                <Route path={'/CostReduction'} element={<CostReduction/>}></Route>
                <Route path={'/StaffAccommodation'} element={<StaffAccommodation/>}></Route>
                <Route path={'/HrConsultancy'} element={<HrConsultancy/>}></Route>
                <Route path={'/Services'} element={<Services/>}></Route>
                <Route path={'/Recruitment'} element={<Recruitment/>}></Route>
                <Route path={'/StaffingManagement'} element={<StaffingManagement/>}></Route>
                <Route path={'/360Hiring'} element={<Hiring/>}></Route>
                <Route path={'/OurReferences'} element={<OurReferences/>}></Route>
                <Route path={'/Hospitality'} element={<Hospitality/>}></Route>
                <Route path={'/Manufacturing'} element={<Manufacturing/>}></Route>
                <Route path={'/Construction'} element={<Construction/>}></Route>
                <Route path={'/Solutions'} element={<Solutions/>}></Route>
                <Route path={'/DemandForm'} element={<DemandForm/>}></Route>
                <Route path={'/Network'} element={<Network/>}></Route>
                <Route path={'/AgentForm'} element={<AgentForm/>}></Route>
                <Route path={'/Portal'} element={<Portal/>}></Route>
                <Route path={'/Individuals'} element={<Individuals/>}></Route>
                <Route path={'/Locations'} element={<Locations/>}></Route>
                <Route path={'/Loader'} element={<Loader/>}></Route>

            </Routes>
            <Footer/>
        </div>

    );
}

export default App;
