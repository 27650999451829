import s from './Individuals.module.css'
import IndividualsImg from "../../assets/img/Individuals/Individuals.webp";
import {useTranslation} from "react-i18next";


export const Individuals = () => {

    const {t} = useTranslation()

    return (
        <div className={s.Individuals}>
            <div className={s.IndividualsBG}>
                <h3>{t("individuals")}</h3>
            </div>
            <div className={s.Wrapper}>
                <div className={s.Text}>
                    <h4 className={s.Title}>
                        {t("individuals")}
                    </h4>
                    <p className={s.Desc}>
                        {t("individuals-text")}
                    </p>
                    <img className={s.descImg} src={IndividualsImg} alt="Individuals"/>
                </div>
            </div>
        </div>
    )
}