import s from "../StaffAccommodation/StaffAccommodation.module.css";
import solution1 from "../../assets/img/CorporateSupport/solution1.webp";
import solution2 from "../../assets/img/CorporateSupport/solution2.webp";
import solution3 from "../../assets/img/CorporateSupport/solution3.webp";
import {useTranslation} from "react-i18next";

export const StaffAccommodation = () => {

    const {t} = useTranslation()

    return (
      <div>
          <div className={s.StaffAccommodation}>
              <div className={s.StaffAccommodationBG}>
                  <h3>{t("staff-accommodation")}</h3>
              </div>
          </div>
          <div className="container">
              <div className={s.Wrapper}>
                  <div className={`${s.Flex} ${s.FlexColumn}`}>
                      <div className={s.FlexText}>
                          <h5 className={s.FlexTitle}>{t("staff-accommodation-title1")}</h5>
                          <p>
                              {t("staff-accommodation-text1")}
                          </p>
                      </div>
                      <div className={`${s.FlexImg} ${s.FlexImgDouble}`}>
                          <img src={solution1} alt="solution1"/>
                          <img src={solution2} alt="solution2"/>

                      </div>
                  </div>
                  <div className={`${s.Flex} ${s.Flex2}`}>
                      <div className={s.FlexText}>
                          <h5 className={s.FlexTitle}>{t("staff-accommodation-title2")}</h5>
                          <p>
                              {t("staff-accommodation-text2")}
                          </p>
                      </div>
                      <div className={s.FlexImg}>
                          <img src={solution3} alt="solution3"/>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  )
}