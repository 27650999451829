import s from './Footer.module.css'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const Footer = () => {

    const {t} = useTranslation()

  return(
      <footer className={s.footer}>
          <div className="container">
              <div className={s.footerWrapper}>
                  <div className={s.footerText}>
                      <ul className={s.footer__links}>
                          <li><Link to={'/aboutUs'}>{t("about-avrasya")}</Link></li>
                          <li><Link to={'/Ethics-Compliance'}>{t("ethics-compliance")}</Link></li>
                          <li><Link to={'/Relationship-based'}>{t("relationship-based")}</Link></li>
                          <li><Link to={'/CodeOfConduct'}>{t("Code-of-conduct")}</Link></li>
                      </ul>
                      <ul className={s.footer__links}>
                          <li><Link to={'/Recruitment'}>{t("recruitment")}</Link></li>
                          <li><Link to={'/StaffingManagement'}>{t("staffing-management")}</Link></li>
                          <li><Link to={'/360Hiring'}>{t("360hiring")}</Link></li>
                          <li><Link to={'/HrConsultancy'}>{t("hr-consultancy")}</Link></li>
                      </ul>
                      <ul className={s.footer__links}>
                          <li><Link to={'/Solutions'}>{t("solutions")}</Link></li>
                          <li><Link to={'/Network'}>{t("network")}</Link></li>
                          <li><Link to={'/Locations'}>{t("locations")}</Link></li>
                      </ul>

                  </div>
                  <div className={s.footerLogo}>
                      <h3>© 2018 - 2024 Avrasya Consulting. <br/>{t("terms")}</h3>

                  </div>
              </div>
          </div>
      </footer>
  )
}