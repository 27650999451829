import s from './Solutions.module.css'
import {Link} from "react-router-dom";
import RecruitmentImg from '../../assets/img/Solutions/RecruitmentImg.webp'
import StaffingSolutionsImg from '../../assets/img/Solutions/StaffingSolutionsImg.webp'
import LogisticSolutionsImg from '../../assets/img/Solutions/LogisticSolutionImg.webp'
import {useTranslation} from "react-i18next";
export const Solutions = () => {

    const {t} = useTranslation()

    return (
        <div className={s.Solutions}>
            <div className={s.SolutionsBG}>
                <h3>{t("solutions")}</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>

                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("solutions-title1")}</h5>
                            <p>
                                {t("solutions-text1")}<Link to={'/Recruitment'}>{t("solutions-link1")}</Link>{t("solutions-text1.5")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={RecruitmentImg} alt="Recruitment"/>
                        </div>
                    </div>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("solutions-title2")}</h5>
                            <p>
                                {t("solutions-text2")}<Link to={'/360Hiring'}>{t("solutions-link1")}</Link>{t("solutions-text2.5")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={StaffingSolutionsImg} alt="Staffing Solutions"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("solutions-title3")}</h5>
                            <p>
                                {t("solutions-text3")}<Link to={"/StaffAccommodation"}>{t("solutions-link3")}</Link> {t("solutions-text3.5")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={LogisticSolutionsImg} alt="Logistic Solutions"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}