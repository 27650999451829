import {Link} from "react-router-dom";
export const MediaCard = ({img,title,mediaTitle,desc,linkTitle,link}) => {
  return (
      <div className="media-card">
          <div>
              <p className="media__name">{mediaTitle}</p>
              <img className="media-card__img" src={img} alt={title}/>
              <div className="media-card-filter"></div>
              <h4 className="media-card__title">{title}</h4>
              <p className="media-card__desc">{desc}</p>
              <Link target="_blank" to={`${link}`}>{linkTitle}</Link>
          </div>
      </div>
  )
}