import s from './RelationshipBased.module.css'
import RelationshipBasedImg from "../../assets/img/RelationshipBased/RelationshipBased.webp";
import {useTranslation} from "react-i18next";

export const RelationshipBased = () => {

    const {t} = useTranslation()

    return (
        <div className={s.RelationshipBased}>
            <div className={s.RelationshipBasedBG}>
                <h3>{t("relationship-based")}</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={s.Text}>
                        <h4 className={s.Title}>
                            {t("relationship-base-title")}
                        </h4>
                        <p className={s.Desc}>
                            {t("relationship-base-text1")}
                            <br/>
                            <br/>
                            {t("relationship-base-text2")}
                            <br/>
                            <br/>
                            {t("relationship-base-text3")}
                        </p>
                        <img className={s.descImg} src={RelationshipBasedImg} alt="Relationship - Based"/>
                    </div>

                </div>
            </div>

        </div>
    )
}