import s from './OurReferences.module.css'
import {Hospitality} from "../Hospitality/Hospitality";
import {Manufacturing} from "../Manufacturing/Manufacturing";
import {Construction} from "../Construction/Construction";
import {useState} from "react";


export const OurReferences = () => {
    const [switcher, setSwitcher] = useState(0)
    const handlerClick = (props) => {
        setSwitcher(props)
    }

    let content = <>
        <Hospitality/>
        <Manufacturing/>
        <Construction/>
    </>


    if (switcher === 1) {
        content = <Hospitality/>
    }
    if (switcher === 2) {
        content = <Manufacturing/>
    }
    if (switcher === 3) {
        content = <Construction/>
    }

    return (
        <div className={s.OurReferences}>
            <div className={s.Wrapper}>

                    <ul className={s.Switcher}>
                        <li>
                            <button onClick={() => handlerClick(0)}>All</button>
                        </li>
                        <li>
                            <button onClick={() => handlerClick(1)}>Hospitality</button>
                        </li>
                        <li>
                            <button onClick={() => handlerClick(2)}>Manufacturing</button>
                        </li>
                        <li>
                            <button onClick={() => handlerClick(3)}>Construction</button>
                        </li>
                    </ul>
                {content}
            </div>
        </div>
    )
}