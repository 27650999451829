import s from './StaffingManagement.module.css'
import MarketDemandsImg from '../../assets/img/StaffManagement/MarketDemandsImg.webp'
import EmployerDemandsImg from '../../assets/img/StaffManagement/EmployerDemandImg.webp'
import TalentAcquisitionImg from '../../assets/img/StaffManagement/TalentAcquisitionImg.webp'
import TrainingImg from '../../assets/img/StaffManagement/TrainingImg.webp'
import PayrollManagementImg from '../../assets/img/StaffManagement/PayrollManagementImg.webp'
import {useTranslation} from "react-i18next";
export const StaffingManagement = () => {

    const {t} = useTranslation()

    return (
        <div className={s.StaffingManagement}>
            <div className={s.StaffingManagementBG}>
                <h3>{t("staffing-management")}</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("staffing-management-title1")}</h5>
                            <p>
                                {t("staffing-management-text1")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={MarketDemandsImg} alt="Market Demands"/>
                        </div>
                    </div>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("staffing-management-title2")}</h5>
                            <p>
                                {t("staffing-management-text2")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={EmployerDemandsImg} alt="Empoyer Demands"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("staffing-management-title3")} </h5>
                            <p>
                                {t("staffing-management-text3")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={TalentAcquisitionImg} alt="Talent Acquisition"/>
                        </div>
                    </div>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("staffing-management-title4")}</h5>
                            <p>
                                {t("staffing-management-text4")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={TrainingImg} alt="Training"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>{t("staffing-management-title5")}</h5>
                            <p>
                                {t("staffing-management-text5")}
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={PayrollManagementImg} alt="Payroll Management"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}